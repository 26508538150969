<template>
  <b-row>
    <b-col cols="12">
      <v-card
          flat
          title="Παραστατικά"
      >
        <v-card-title>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Αναζήτηση"
              single-line
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="fields"
            :items="invoices"
            :items-per-page="10"
            class="elevation-1"
            :search="search"
            @click:row="onRowDoubleClick"
        >
          <template v-slot:item.document_total="{ value }">
           <span v-if="value"> {{ value }} €</span>
          </template>
          <template v-slot:item.document_date="{ value }">
           <span v-if="value"> {{ formattedDate(value) }} </span>
          </template>
          <template v-slot:item.document_payment_status="{ value }">
           <span v-if="value" v-html="invoiceStatuses[value]"></span>
          </template>
        </v-data-table>
      </v-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router';
import {formatDateToMMDDYYYYHHMM} from "@/utils";

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: '',
      fields: [
        {
          text: 'Κατάσταση',
          align: 'start',
          sortable: true,
          value: 'document_payment_status',
        },
        {
          text: 'Ημερομηνία',
          // align: 'start',
          sortable: true,
          value: 'document_date',
        },
        {
          text: 'Συναλλασόμενος',
          // align: 'start',
          sortable: true,
          value: 'document_operator_name',
        },
        {
          text: 'Τύπος Παραστατικού',
          // align: 'start',
          sortable: true,
          value: 'document_type_name',
        },
        {
          text: 'Αριθμός',
          // align: 'start',
          sortable: true,
          value: 'document_number',
        },
        {
          text: 'Τελική Αξία',
          // align: 'start',
          sortable: true,
          value: 'document_total',
        },
      ],
    }
  },
  computed: {
    invoices() {
      return this.$store.getters['data/getInvoices']
    },
    invoiceStatuses() {
      return this.$store.getters['data/getInvoiceStatuses']
    },
  },
  mounted() {
    this.$store.dispatch('data/fetchInvoices')
  },
  methods: {
    // eslint-disable-next-line camelcase
    loadOffers(auction_id) {
      this.$store.dispatch('data/fetchAuctionOffers', auction_id).then(() => {
        this.$router.push('/offers')
      })
    },
    onRowDoubleClick(e, obj) {
      router.push({ name: 'invoice-details', params: { connection_id: obj.item.connection_id } });
    },
    formattedDate(date) {
      return formatDateToMMDDYYYYHHMM(date);
    },
  },
}
</script>
