import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":"","title":"Παραστατικά"}},[_c(VCardTitle,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Αναζήτηση","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.fields,"items":_vm.invoices,"items-per-page":10,"search":_vm.search},on:{"click:row":_vm.onRowDoubleClick},scopedSlots:_vm._u([{key:"item.document_total",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value)+" €")]):_vm._e()]}},{key:"item.document_date",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm.formattedDate(value))+" ")]):_vm._e()]}},{key:"item.document_payment_status",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.invoiceStatuses[value])}}):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }