// export function formatDateToMMDDYYYYHHMM(dateTime) {
//     const options = {
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit',
//         hour: '2-digit',
//         minute: '2-digit',
//         hour12: false,
//     };
//
//     const date = new Date(dateTime);
//     return date.toLocaleString('el-GR', options).replace(',', ' ');
// }
export function formatDateToMMDDYYYYHHMM(dateTime) {
    // Ensure dateTime is in a format that's widely recognized. ISO 8601 is recommended.
    // If dateTime is not already in an ISO 8601 format, consider converting it here.

    // Parse the dateTime to a Date object. This assumes dateTime is in a valid format.
    const date = new Date(dateTime);
    if (isNaN(date.getTime())) {
        // Handle invalid date, perhaps by returning a default value or an error message.
        return 'Invalid Date'; // or any other fallback logic
    }

    // Manually format the date to MM/DD/YYYY HH:MM
    // Ensure month, day, hour, and minute are 2 digits.
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // getMonth() is zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);

    // Construct the formatted date string in MM/DD/YYYY HH:MM format
    const formattedDate = `${day}/${month}/${year} ${hour}:${minute}`;

    return formattedDate;
}
